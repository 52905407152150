import React, { useEffect, useRef, useState } from 'react';
import { globalState } from '@/stores';
import PepprDatePicker from '@/Components/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Flex, Spin } from 'antd';
import ReactToPrint from 'react-to-print';
import useGetState from '@/hooks/useGetState';
import { ApiQuerySaleReportNew } from '@/request/api';
import { getToday } from '@/utils';
import './index.scss'
import './NewIndex.scss'
import TotalSummary from './TotalSummary';
import NetSalesSummary from './NetSalesSummary';
import NetSalesList from './NetSalesList';
import { IParams, IReportData } from './interface';
import SalesCategoryList from '@/Pages/SalesSummary/SalesCategoryList';
import PaymentsList from '@/Pages/SalesSummary/PaymentsList';
import ServiceChargeList from '@/Pages/SalesSummary/ServiceChargeList';
import { SyncOutlined } from '@ant-design/icons';
import { getCookie } from '@/utils';
import InfoCard from './components/InfoCard'
import Comps from './Comps'
import SalesByDayPart from './SalesByDayPart'
export default function NewSalesSummary () {
  const { i18n } = globalState;

  const [loading, setLoading] = useState<boolean>(true)
  const [params, setParams, getParams] = useGetState<IParams>({
    date: [dayjs(), dayjs()]
  })
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [reportData, setReportData] = useState<Partial<IReportData>>({})
  const printRef = useRef(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _today = await getToday();
    setToday(_today);
    setParams({ date: [_today, _today] });
    fetchData();
  }

  const handleChangeDate = (values) => {
    setParams({ date: values })
    fetchData();
  }

  const fetchData = async () => {
    const _params = getParams();
    const result = {
      params: {
        req: {
          beginTime: _params.date[0].format('YYYY-MM-DD 00:00:00'),
          endTime: _params.date[1].format('YYYY-MM-DD 23:59:59')
        }
      }
    }
    setLoading(true)
    const res = await ApiQuerySaleReportNew(result);
    setLoading(false)
    if (res?.code === 0) {
      setReportData(res.data || {});
    }
  }

  return (
    <div className="sales-summary-warp print-container" ref={ printRef }>
      <Spin size="large" spinning={ loading }>
        <div className="m-title">
          <div className="title">{ i18n.t('Sales_pc_summary') }</div>
        </div>
        <div className="content-wrap">
          <div className="filter-wrap">
            <div className="filter-left-wrap">
              <PepprDatePicker value={ params.date } onChange={ handleChangeDate } today={ today }/>
            </div>
            <div className="filter-right-wrap">
              <Button icon={ <SyncOutlined/> } onClick={ fetchData }/>
              {getCookie('appCode') !== '1' && <ReactToPrint
                trigger={ () => <Button icon={ <div className="iconfont icon-print"/> }/> }
                content={ () => printRef.current }
              />}
            </div>
          </div>
          <TotalSummary item={ reportData.revenueSummaryDTO }/>
          <NetSalesSummary reportData={ reportData }/>
          <NetSalesList reportData={ reportData }/>
          <SalesCategoryList reportData={ reportData }/>
          <PaymentsList list={ reportData.paymentStatsList }/>
          <ServiceChargeList list={ reportData.serviceChargeStatsList }/>
          <Flex gap="middle" wrap className='flex-box'>
            <div style={{ flex: '1 1 0', minWidth: '25%' }}>
              <InfoCard infoData={ reportData.taxList } title={ 'Tax by Tax ID' } setInfoKey={{ title: 'taxName', key: 'taxUuid', content: 'taxAmount' }}/>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '25%' }}>
              <InfoCard infoData={ reportData.dayPartSummaryDTO?.dayPartGuestCountList } title={ 'Guest Count by Day Part' } setInfoKey={{ title: 'dayPartName', content: 'guestCount' }}/>
            </div>
          </Flex>
          <Flex gap="middle" wrap className='flex-box'>
            <div style={{ flex: '1 1 0', minWidth: '25%' }}>
              <InfoCard infoData={ reportData.dayPartSummaryDTO?.dayPartCheckCountList } title={ 'Check Count by Day Part' } setInfoKey={{ title: 'dayPartName', content: 'guestCount' }}/>
            </div>
            <div style={{ flex: '1 1 0', minWidth: '25%' }}>
              <InfoCard infoData={ reportData.diningOptionGuestCountList } title={ 'Guest Count by Order Mode' } setInfoKey={{ title: 'diningOptionName', key: 'diningOptionId', content: 'guestCount' }}/>
            </div>
          </Flex>
          <Flex gap="middle" wrap className='flex-box'>
            <Comps infoData={ reportData.promotionSummaryList } title={ 'Comps' } setInfoKey={{ title: 'promotionName', info: 'promotionCount', key: 'promotionUuid', content: 'promotionAmount' }}/>
          </Flex>
          <SalesByDayPart salesCategorySummaryDTO={reportData.dayPartSummaryDTO?.salesCategorySummaryDTO} />
        </div>
      </Spin>
    </div>
  )
}