import { Button, Form, Input, Table } from 'antd';
import { SearchOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TableEmptyContent } from '@/Components/EmptyContent';
import React, { useEffect, useRef, useState } from 'react';
import { toRes } from '@/utils';
import { ApiBreakList } from '@/request/api';
import { ReactComponent as SearchIcon } from '@/assets/svg/search.svg';
import { ReactComponent as UnarchivedIcon } from '@/assets/svg/unarchived.svg';
import { ReactComponent as ArchiveIcon } from '@/assets/svg/archive.svg';
import { globalState } from '@/stores';
import createModal from '@/utils/createModal';
import './index.scss';
import EditBreaks from './components/editBreaks';
import ModalArchiveConfirm from './ModalArchiveConfirm';

export default function Breaks () {
  const { i18n } = globalState;

  const [form] = Form.useForm();
  const paramsRef = useRef({ keyword: '' });

  const [tableData, setTableData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({ archived: null });

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  const [pager, setPager] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => i18n.t('table_total_items', { num: total }),
  });

  const typeObj = {
    1: i18n.t('rms_overseas_transaction_order_OrderPayStatus_PAID'),
    2: i18n.t('rms_overseas_transaction_order_OrderPayStatus_UNPAID'),
  }

  const tableColumns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: i18n.t('dashboard_team_breaks'),
      width: 250,
      render: (_, row) => row.name,
    },
    {
      dataIndex: 'type',
      key: 'type',
      title: i18n.t('dashboard_team_breaks_type'),
      width: 150,
      render: (_, row) => (
        <div>
          {typeObj[row.type]}
        </div>
      ),
    },
    {
      dataIndex: 'duration',
      key: 'duration',
      title: i18n.t('dashboard_team_breaks_break_length'),
      width: 150,
      render: (_, row) => <div> {row.duration} mins </div>,
    },
    {
      dataIndex: 'mandatory',
      key: 'mandatory',
      title: i18n.t('dashboard_team_breaks_mandatory_break'),
      width: 150,
      render: (_, row) => <div>{row.mandatory === 1 && <CheckOutlined/>}</div>,
    },
    {
      dataIndex: 'archived',
      key: 'archived',
      title: i18n.t('status'),
      width: 150,
      filteredValue: filteredInfo.archived || null,
      filterMultiple: false,
      filters: [
        { text: i18n.t('active'), value: 0 },
        { text: i18n.t('archived'), value: 1 },
      ],
      render: (_, row) => {
        const _isActive = !row?.archived;
        return (
          <div className="colStatus">
            <div className={_isActive ? 'greenPoint' : 'greyPoint'}></div>
            {_isActive ? i18n.t('active') : i18n.t('archived')}
          </div>
        );
      },
    },
    {
      key: 'actions',
      title: i18n.t('table_actions'),
      width: 250,
      render: (_, row) => {
        const _isArchived = row?.archived;
        return (
          <div className="colActions">
            <Button
              type="link"
              className="btnEdit"
              onClick={() => handleEdit(row)}
            >
              <EditOutlined />
              <span className="text">{i18n.t('edit')}</span>
            </Button>
            {_isArchived ? (
              <Button type="link" onClick={() => handleArchive(row)}>
                <ArchiveIcon />
                <span className="text">{i18n.t('unarchived')}</span>
              </Button>
            ) : (
              <Button type="link" onClick={() => handleArchive(row)}>
                <UnarchivedIcon />
                <span className="text">{i18n.t('archive')}</span>
              </Button>
            )}
          </div>
        );
      },
    },
  ];



  const fetchTableData = async ({
    current,
    pageSize,
    filters,
  }: { current?: number; pageSize?: number; filters?: object } = {}) => {
    const filterObj = Object.entries(filters ?? filteredInfo).reduce(
      (result, [key, value]) => {
        result[key] = value?.[0];
        return result;
      },
      {}
    );
    const params = {
      request: {
        keyword: paramsRef.current.keyword,
        ...filterObj,
      },
      current: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize,
    };
    const [_, data] = await toRes(ApiBreakList(params));
    setTableData(data?.list ?? []);
    setPager((prev) => ({
      ...prev,
      // pageSize: pageSize,
      total: data?.total ?? 0,
      current: data?.current,
    }));
  };

  const handleEdit = (row?) => {
    setEditModalVisible(true);
    setCurrentRow(row);
  };

  const handleCancel = () => {
    setEditModalVisible(false);
  };

  const handleTableChange = (_pagination, filters) => {
    setFilteredInfo(filters);
    fetchTableData({
      current: _pagination.current,
      pageSize: _pagination.pageSize,
      filters,
    });
  };

  const handleSearch = () => {
    updateParamsRef();
    fetchTableData({ current: 1 });
  };

  const handleArchive = (item) => {
    createModal(ModalArchiveConfirm, {
      item,
      fetchData: fetchTableData,
    }).open();
  };

  const updateParamsRef = () => {
    paramsRef.current = form.getFieldsValue();
  };



  useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <div className="employeeListJobs">
      <div className="m-title">
        <div className="title">{i18n.t('dashboard_team_breaks')}</div>
      </div>
      <div className="filterWrap">
        <Form form={form} layout="inline">
          <Form.Item name={'keyword'}>
            <Input
              prefix={<SearchIcon />}
              className="inputName"
              placeholder={i18n.t('dashboard_roles_edit_roles_select_a_break')}
            />
          </Form.Item>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            {i18n.t('search')}
          </Button>
        </Form>

        <Button
          className="add-btn"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            handleEdit();
          }}
        >
          {i18n.t('dashboard_teams_breaks_add_new_break')}
        </Button>
      </div>
      <div className="tableWrap">
        <Table
          columns={tableColumns}
          pagination={pager}
          dataSource={tableData.map((x, i) => ({ ...x, key: i }))}
          rowKey={'key'}
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
          locale={{ emptyText: TableEmptyContent }}
        />
      </div>

      {editModalVisible && (
        <EditBreaks
          open={editModalVisible}
          handleCancel={handleCancel}
          currentRow={currentRow}
          fetchListData={fetchTableData}
        />
      )}
    </div>
  );
}
