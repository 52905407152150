import React, { useEffect, useMemo, useState } from 'react'
import { Empty, Table } from 'antd'
import { formatDollar, formatPrice } from '@/utils';
import { globalState } from '@/stores';
import './index.scss'
import Price from '../components/Price';
import classNames from 'classnames';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { set } from 'mobx';
interface SalesCategorySummaryDTO {
  columnName: string[];
  salesCategoryDayPartList: {
    salesCategoryName: string;
    salesCategoryId: any;
    dayPartAmountList: string[];
  }[];
}
interface TableColumn {
  title: string;
  dataIndex: string;
  key: string;
}

interface TableDataItem {
  key: string;
  salesCategoryName: string;
  salesCategoryId: any;
  [key: string]: string;
}

export default function SalesByDayPart ({ salesCategorySummaryDTO }) {
  const { i18n } = globalState;
  const [tableData, setTableData] = useState([])
  const [tableColumns, setTableColumns] = useState([])

  useEffect(() => {
    salesCategorySummaryDTO && convertToTableFormat(salesCategorySummaryDTO)
  }, [salesCategorySummaryDTO]);
  const convertToTableFormat = (data: SalesCategorySummaryDTO) => {
    const columns: TableColumn[] = data.columnName.map((name, index) => (name ?
      {
        title: name,
        dataIndex: index === 0 ? 'salesCategoryName' : `column${index}`,
        key: index === 0 ? 'salesCategoryName' : `column${index}`,
      } : {
        title: '',
        dataIndex: index === 0 ? 'salesCategoryName' : `column${index}`,
        key: index === 0 ? 'salesCategoryName' : `column${index}`,
        render: (val, row) => row.salesCategoryId === '-1' ? i18n.t('pc_sales_summary_no_sales_category_assigned') : val
      }
    ));

    const dataSource: TableDataItem[] = data.salesCategoryDayPartList.map((item, index) => {
      const rowData: TableDataItem = {
        key: `row${index}`,
        salesCategoryName: item.salesCategoryName,
        salesCategoryId: item.salesCategoryId,
      };

      item.dayPartAmountList.forEach((amount, amountIndex) => {
        rowData[`column${amountIndex + 1}`] = amount;
      });

      return rowData;
    });
    console.log(columns, dataSource)
    setTableColumns(columns)
    setTableData(dataSource)
    return { columns, dataSource };
  }
  return (
    <div className="sales-summary-net-sales-list">
      <div className="header-wrap">
        <div className="sales-list-title-wrap">
          <span>Sales By Day Part By Cat</span>
        </div>

      </div>
      <div
        className={classNames('net-sales-list-table-wrap', {
          'no-data': tableData.length === 0
        })}>
        {tableData.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
          <Table
            columns={tableColumns}
            dataSource={tableData}
            rowClassName={ (_, index) => classNames('row', { 'sticky-row': index === tableData.length - 1 }) }
            scroll={{
              y: 410,
              x: 'max-content'
            }}
            pagination={false}
          />
        }
      </div>
    </div>
  )
}