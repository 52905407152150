import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, InputNumber, message, Popover, Radio, Spin, TimePicker, Select, Switch } from 'antd';
import './index.scss';
import { globalState } from '@/stores';
import { ApiThirdChannelConfigQuery, ApiThirdChannelConfigSave, ApiIsOnlineOrderingDeviceList } from '@/request/api';
import {
  IBusinessHour,
  IDoorDashConfig,
  QUOTE_TIME_ENUM,
  QUOTE_TIME_ENUM_I18N,
  ISpecialHour
} from '@/Pages/OnlineOrders/interface';
import { to } from '@/utils';
import NP from '@/utils/NP';
import PickupHours from '../components/PickupHours';
import SpecialHours from '../components/SpecialHours';
import dayjs from 'dayjs';

interface IProps {
  isActive: boolean
}
interface IForm {
  orderSwitch: boolean,
  quoteTimeMode: QUOTE_TIME_ENUM,
  quoteTimeCustomMin: number,
  quoteTimeCustomHour: number,
  openHours: IBusinessHour[]
  specialHours: ISpecialHour[]
  posDevices: string[]
  specialHourSwitch: boolean
  priceMarkUp: number
}
interface ISelectProps {
  label: string;
  value: string
}

export default function Pickup (props: IProps) {
  const { i18n } = globalState;
  const { isActive } = props;
  const [form] = Form.useForm<IForm>()
  const [isInit, setIsInit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const backupDataRef = useRef<string>('');
  const watchedQuoteTimeMode = Form.useWatch(['quoteTimeMode'], form);
  const watchedSpecialHourSwitch = Form.useWatch(['specialHourSwitch'], form);
  const [deviceListLoading, setDeviceListLoading] = useState<boolean>(false)
  const [deviceList, setDeviceList] = useState<Array<ISelectProps>>([]) // 设备列表


  useEffect(() => {
    if (isActive && !isInit) {
      fetchDetail();
    }
  }, [isActive]);

  const fetchDetail = async () => {
    setLoading(true);
    const res = await ApiThirdChannelConfigQuery({ thirdSource: 2 });
    setLoading(false);
    if (!res?.data) return;
    backupDataRef.current = JSON.stringify(res.data.doorDashConfig);
    initForm();
    setIsInit(true);
  }

  const initForm = () => {
    const data: IDoorDashConfig = JSON.parse(backupDataRef.current);
    const {
      openHours,
      orderSwitch,
      specialHourSwitch,
      specialHours,
      posDevices,
      quoteTime,
      priceMarkUp
    } = data;
    const { hours: quoteTimeCustomMin, minutes: quoteTimeCustomHour } = getHoursAndMinutes(quoteTime.customMinutes)
    form.setFieldsValue({
      openHours,
      orderSwitch,
      specialHourSwitch,
      posDevices,
      quoteTimeMode: quoteTime.mode,
      quoteTimeCustomMin,
      quoteTimeCustomHour,
      priceMarkUp: priceMarkUp?.value ? priceMarkUp?.value * 100 : null,
      specialHours: specialHours && specialHours.length > 0 ? specialHours.map(item => ({
        ...item,
        dateRange: [dayjs(item.startDate), dayjs(item.endDate)],
        intervalTimes: [item.startTime,  item.endTime]
      })) : [{
        open: true,
        startTime: null,
        endTime:null,
        dateRange: null,
        startDate: null,
        endDate: null,
        intervalTimes: null }]
    });
  }
  const getHoursAndMinutes = (totalMinutes) => {
    const maxHours = 999;
    let hours = Math.floor(NP.divide(totalMinutes, 60));
    let minutes = totalMinutes % 60;

    if (hours >= maxHours) {
      minutes = NP.plus(minutes, NP.times(NP.minus(hours, maxHours), 60));
      hours = maxHours;
    }

    return { hours, minutes };
  }

  const formRules = {
    quoteTimeMode: [() => ({
      validator (_, value) {
        if (value === QUOTE_TIME_ENUM.CUSTOM && form.getFieldValue('quoteTimeCustomMin') === 0 && form.getFieldValue('quoteTimeCustomHour') === 0) {
          return Promise.reject(new Error(i18n.t('pc_online_orders_quote_time_cannot')))
        }
        return Promise.resolve()
      },
    })]
  }

  const handleEdit = () => {
    // if (!backupDataRef.current) return;
    setIsEdit(true);
  }

  const handleCancel = () => {
    // FIXME 之后还是要改造一下这个重置流程
    form.resetFields(['pickup_hours'])
    initForm();
    clearError();
    setIsEdit(false);
  }

  const clearError = () => {
    const allFormName = Object.keys(form.getFieldsValue())
    form.setFields(allFormName.map(x => ({ name: x, errors: [] })))
  }

  const handleSave = async () => {
    const [err, formParams] = await to(form.validateFields());
    if (err) return;
    const {
      openHours,
      orderSwitch,
      specialHourSwitch,
      specialHours,
      posDevices,
      quoteTimeMode,
      quoteTimeCustomMin,
      quoteTimeCustomHour,
      priceMarkUp
    } = formParams;
    const doorDashConfig = {
      openHours,
      orderSwitch,
      specialHourSwitch,
      specialHours: specialHours ? specialHours.map(item => ({
        open: item.open,
        startDate: dayjs(item.dateRange[0]).format('YYYY-MM-DD'),
        endDate: dayjs(item.dateRange[1]).format('YYYY-MM-DD'),
        startTime: item.intervalTimes[0],
        endTime: item.intervalTimes[1]
      })) : null,
      posDevices,
      quoteTime: {
        mode: quoteTimeMode,
        customMinutes: quoteTimeCustomMin * 60 + quoteTimeCustomHour
      },
      priceMarkUp: priceMarkUp === null ? null : {
        value: Number(priceMarkUp) > -1 ? NP.divide(Number(priceMarkUp), 100) : 0.00,
      }
    }
    setLoading(true)
    const res = await ApiThirdChannelConfigSave({ doorDashConfig });
    if (res.code === 0) {
      message.success(res.message);
      setIsEdit(false);
      await fetchDetail();
    }
    setLoading(false)
  }


  const handleBlurQuoteTime = (e, field) => {
    setTimeout(() => {
      if (form.getFieldValue(field) === null || form.getFieldValue(field) === '') {
        form.setFieldValue(field, 0);
      }
      form.validateFields(['quoteTimeMode']);
    }, 0)
  }

  const getDisabled = (val) => {
    if (!isEdit) return true;
    return val;
  }
  // 拉取当前可用的Pos List
  const queryDeviceList = async () => {
    setDeviceListLoading(true);
    const res = await ApiIsOnlineOrderingDeviceList();
    setDeviceListLoading(false);
    if (!res?.data) return;
    const result = res.data.map(item => ({
      label: item.posDevice?.sn,
      value: item.posDevice?.sn
    }))
    setDeviceList(result);
  }
  // 下拉框展示时拉取数据
  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      queryDeviceList()
    }
  }
  return (
    <Spin spinning={ loading } wrapperClassName="spin-wrap">
      <div className="third-party-ordering">
        <div className="form-wrap">
          <Form form={ form } layout="vertical" requiredMark={ false } disabled={ !isEdit }>
            {/* <Form.Item name={ 'orderSwitch' } label={ i18n.t('dashboard_integrations_dd_addOrderPlatform') }>
              <Radio.Group options={ [{ value: true, label: i18n.t('pc_online_orders_on') }, { value: false, label: i18n.t('pc_online_orders_off') }] } style={ { display: 'grid' } }/>
            </Form.Item> */}
            <Form.Item
              name={ 'orderSwitch' }
              colon={ false }
              label={i18n.t('dashboard_3po_acceptOrders')}
              layout="horizontal"
            >
              <Switch />
            </Form.Item>
            <div className="title">{ i18n.t('dashboard_3po_quote_time') }</div>
            <div className='text-quote-time'>{ i18n.t('PC_OO_QuoteTimeDescription')}</div>
            <Form.Item name='quoteTimeMode' label={ i18n.t('') }  rootClassName="quote-time-mode-wrap" rules={ formRules.quoteTimeMode }>
              <Radio.Group style={ { display: 'grid' } }>
                <Radio value={ QUOTE_TIME_ENUM.ASAP }>{ i18n.t(QUOTE_TIME_ENUM_I18N[QUOTE_TIME_ENUM.ASAP]) }</Radio>
                <Radio value={ QUOTE_TIME_ENUM.CUSTOM }>{ i18n.t(QUOTE_TIME_ENUM_I18N[QUOTE_TIME_ENUM.CUSTOM]) }</Radio>
                <div className="quote-time-input-wrap">
                  <Form.Item name='quoteTimeCustomMin'>
                    <InputNumber
                      addonAfter={ i18n.t('pc_online_orders_hour') } min={ 0 } max={ 999 } precision={ 0 } style={ { width: 150 } }
                      disabled={ getDisabled(watchedQuoteTimeMode === QUOTE_TIME_ENUM.ASAP) }
                      onBlur={ (e) => handleBlurQuoteTime(e, 'quoteTimeCustomMin') }
                    />
                  </Form.Item>
                  <Form.Item name='quoteTimeCustomHour'>
                    <InputNumber
                      addonAfter={ i18n.t('pc_online_orders_min') } min={ 0 } max={ 999 } precision={ 0 } style={ { width: 150 } }
                      disabled={ getDisabled(watchedQuoteTimeMode === QUOTE_TIME_ENUM.ASAP) }
                      onBlur={ (e) => handleBlurQuoteTime(e, 'quoteTimeCustomHour') }
                    />
                  </Form.Item>
                </div>
              </Radio.Group>
            </Form.Item>
            <div className="title">{ i18n.t('dasboard_3po_dd_priceMarkup') }</div>
            <div className='text-quote-time'>{ i18n.t('dasboard_3po_dd_priceMarkup_desc')}</div>
            <Form.Item
              name={ 'priceMarkUp' }
              colon={ false }
            >
              <InputNumber
                addonAfter='%'
                step='1.00'
                min={0}
                precision={2}
                placeholder={i18n.t('dashboard_menu_enterPercent')} />
            </Form.Item>
            <PickupHours formKey='openHours' title={i18n.t('dashboard_3po_dd_hours')} />
            <Form.Item
              name={ 'specialHourSwitch' }
              colon={ false }
              label={i18n.t('dashboard_3po_dd_special_hours')}
              layout="horizontal"
            >
              <Switch />
            </Form.Item>
            {watchedSpecialHourSwitch && <SpecialHours isEdit={isEdit} />}
            <Form.Item name='posDevices' label= {<span>{ i18n.t('pc_device_link_to_POS_station') }</span>} >
              <Select
                style={{ width: '700px' }}
                mode="multiple"
                onDropdownVisibleChange={handleDropdownVisibleChange}
                notFoundContent={deviceListLoading ? <Spin size="small" /> : null}
                options={deviceList}
                allowClear
              />
            </Form.Item>
          </Form>
        </div>
        <div className="action-wrap">
          { isEdit ?
            <>
              <Button onClick={ handleCancel }>{ i18n.t('cancel') }</Button>
              <Button type="primary" onClick={ handleSave } loading={ loading }>{ i18n.t('save') }</Button>
            </>
            : <Button type="primary" onClick={ handleEdit } key={ 'edit' }>{ i18n.t('edit') }</Button>
          }
        </div>
      </div>
    </Spin>
  )
}