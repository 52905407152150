import React, { useRef, useState } from 'react'
import { Tabs } from 'antd'
import LaborSummary from '../LaborSummary'
import EmployeeCheckoutReport from '../EmployeeCheckoutReport'
import EmployeeTimeSheets from '../EmployeeTimeSheets'
import { globalState } from '@/stores'
import './index.scss'

const LaborReport = () => {
  const { i18n } = globalState
  const [tabKey, setTabKey] = useState('1')
  const printRef = useRef(null);

  const items  = [
    {
      label: i18n?.t('overseas_peppr_entrance_laborsummary'),
      key: '1',
      children: <LaborSummary printRef={printRef}  />,
    },
    {
      label: i18n.t('dashboard_checkout_report'),
      key: '2',
      children: <EmployeeCheckoutReport />,
    },
    {
      label: i18n.t('overseas_peppr_entrance_employeeTimeSheets'),
      key: '3',
      children: <EmployeeTimeSheets />,
    },
  ]

  return (
    <div className="labor-report" ref={ printRef }>
      <Tabs
        activeKey={tabKey}
        tabBarExtraContent={{ left: <div className="main-title">{i18n.t('overseas_peppr_entrance_labor') || 'Labor'}</div> }}
        onChange={val => setTabKey(val)}
        items={items}
      ></Tabs>
    </div>
  )
}
export default LaborReport