import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { globalState } from '@/stores';
import { Form, Modal, Select, DatePicker, message, Button, Input, TimePicker, Tooltip } from 'antd';
import { ApiGetBreakList, ApiQueryEmployeeList } from '@/request/api';
import { getToday, formatTime, toRes } from '@/utils';
import dayjs, { Dayjs } from 'dayjs';
import './addTimeSheet.scss';
import {
  ApiCreateTimesheet,
  ApiUpdateTimesheet,
  ApiDeleteTimesheet,
  ICreateTimesheet,
} from '@/request/EmployeeTimeSheets';

const { Option } = Select;

import { DeleteOutlined } from '@ant-design/icons';
import { IBreakItem } from '@/Pages/EmployeeList/Breaks/interface';
export type TimeSheetType = {
  clockIn: string;
  clockOut: string;
  standardClockIn: string;
  standardClockOut: string;
  employeeId: number;
  roleId: number;
  roleName: string;
  firstName: string;
  lastName: string;
  hours: number;
  id: number;
  key: number;
  breakPaidHours: number;
  breakUnpaidHours: number;
  breakTimeList: IBreakItem[];
};
interface IProps {
  openModal: boolean;
  timeSheetInfo?: TimeSheetType;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  handleOk?: () => void;
  handleCancel?: () => void;
}
const { confirm } = Modal;

const AddTimeSheet = (props: IProps) => {
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 },
  };
  const i18n = globalState.i18n;
  const [formInstance] = Form.useForm();
  const [employeeAllList, setEmployeeAllList] = useState([]);
  const [employeeIdAndRoleListMap, setEmployeeIdAndRoleListMap] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [today, setToday] = useState<Dayjs>(null);
  const [formClockIn, setFormClockIn] = useState<Dayjs>(null);
  const [formClockOut, setFormClockOut] = useState<Dayjs>(null);

  // get the last break list
  const [breaksList, setBreaksList] = useState([]);
  const [breakSelectOption, setBreakSelectOption] = useState([]);

  // get time sheet breaks list
  const [timeSheetBreaksList, setTimeSheetBreaksList] = useState([]);

  const typeObj = {
    1: i18n.t('rms_overseas_transaction_order_OrderPayStatus_PAID'),
    2: i18n.t('rms_overseas_transaction_order_OrderPayStatus_UNPAID'),
  }


  useEffect(() => {
    if (props.openModal) {
      setIsEdit(!!props.timeSheetInfo);
      initData();
    }
  }, [props.openModal]);

  const fetchEmployeeList = async () => {
    const _params = { needPage: false, request: { status: 0 } };
    const res = await ApiQueryEmployeeList(_params);
    if (res.code !== 0) return;
    const { list = [] } = res.data;

    const employeeList = [];
    const employeeIdAndRoleListMap = {};

    if (list) {
      list?.forEach((item) => {
        employeeList.push({
          label: item.employee.fullName,
          value: item.employee.employeeId,
        });
        employeeIdAndRoleListMap[item.employee.employeeId] = item.roles || [];
      });
    }
    setEmployeeAllList(employeeList);
    setEmployeeIdAndRoleListMap(employeeIdAndRoleListMap);
  };

  const handleEmployeeChange = (employeeId: number) => {
    // 清空 roleId
    formInstance.setFieldsValue({ roleId: undefined, breakTimeSheetList: [] });
    setBreaksList([])

    const roles = employeeIdAndRoleListMap[employeeId] || [];
    const formattedRoles = roles.map((role) => ({
      label: role.roleName,
      value: role.roleId,
    }));
    setRoleList(formattedRoles);
    // 如果 formattedRoles 只有一个元素，自动设置 roleId
    if (formattedRoles.length === 1) {
      formInstance.setFieldsValue({ roleId: formattedRoles[0].value });
      getBreakAllList()
    }
  };

  const resetData = () => {
    formInstance.resetFields();
    setFormClockIn(null);
    setBreaksList([])
    setTimeSheetBreaksList([])
  };

  const initData = async () => {
    const _today = await getToday();
    setToday(_today);
    fetchEmployeeList();
    if (!!props.timeSheetInfo) {
      const { clockIn, clockOut, employeeId, roleId, breakTimeList } =
        props.timeSheetInfo;
      const clockInTimeStamp = clockIn ? dayjs(new Date(clockIn)) : null;
      const clockOutTimeStamp = clockOut ? dayjs(new Date(clockOut)) : null;
      setFormClockIn(clockInTimeStamp);
      formInstance.setFieldsValue({
        clockIn: clockInTimeStamp,
        clockOut: clockOutTimeStamp,
        employee: employeeId,
        roleId: roleId,
      });
      setTimeSheetBreaksList(breakTimeList || [])
    }
    getBreakAllList()
  };

  const formatRequestParams = async () => {
    try {
      const res = await formInstance.validateFields();
      return res;
    } catch (error) {
      console.log('error', error);
      return false;
    }
  };

  const handleOk = async () => {
    const formatStr = 'YYYY-MM-DD HH:mm:ss';
    const params = await formatRequestParams();
    if (!params) {
      return;
    }
    const { clockIn, clockOut, employee, roleId } = params;
    const newBreakTimeList = breaksList?.map(breakItem => ({
      ...breakItem,
      breakStartTime: dayjs(breakItem.breakStartTime, 'MM/DD/YYYY hh:mm A').format(formatStr),
      breakEndTime: dayjs(breakItem.breakEndTime, 'MM/DD/YYYY hh:mm A').format(formatStr),
    }));
    const oldBreakTimeList = timeSheetBreaksList?.map(breakItem => ({
      ...breakItem,
      breakStartTime: dayjs(breakItem.breakStartTime, 'MM/DD/YYYY hh:mm A').format(formatStr),
      breakEndTime: dayjs(breakItem.breakEndTime, 'MM/DD/YYYY hh:mm A').format(formatStr),
    }));

    const requestParams: ICreateTimesheet = {
      ...(isEdit ? { id: props.timeSheetInfo.id } : { employeeId: employee }),
      clockInTime: formClockIn ? formClockIn.format(formatStr) : clockIn.format(formatStr),
      clockOutTime: formClockOut ? formClockOut.format(formatStr) : clockOut.format(formatStr),
      roleId,
      breakTimeSheetList: [...oldBreakTimeList, ...newBreakTimeList],
    };
    console.log('requestParams===>', requestParams)
    let res = null;
    if (isEdit) {
      res = await ApiUpdateTimesheet(requestParams);
    } else {
      res = await ApiCreateTimesheet(requestParams);
    }
    if (res.code === 0) {
      resetData();
      props.setOpenModal(false);
      props.handleOk && props.handleOk();
    }
  };

  const handleDelete = async () => {
    confirm({
      icon: <></>,
      title: `${i18n?.t('frontOfHouse_pc_delete')} ${i18n?.t('time_sheet')}`,
      content: i18n?.t('delete_the_time_sheet'),
      okText: i18n?.t('rms_overseas_transaction_order_HasRefund_YES'),
      cancelText: <>{i18n?.t('rms_overseas_transaction_order_HasRefund_NO')}</>,
      onOk: async () => {
        const res = await ApiDeleteTimesheet({ ids: [props.timeSheetInfo.id] });
        if (res.code === 0) {
          resetData();
          props.setOpenModal(false);
          props.handleOk && props.handleOk();
        }
      },
    });
  };

  const handleCancel = () => {
    resetData();
    props.setOpenModal(false);
    props.handleCancel && props.handleCancel();
  };

  const clockInDisabledDate = (current) => {
    if (today) {
      const _tomorrow = today.add(1, 'day').endOf('day');
      return current.isAfter(_tomorrow);
    }
    return true;
  };

  const clockInDisabledTime = (currentTime) => {
    const currentTimeDate = currentTime.startOf().valueOf();
    const todayDate = today.startOf('day').valueOf();
    if (today) {
      // 如果日期选择为明天那只能选四点之前的时间，今天或昨天可以选择四点之后的时间
      const _today =
        currentTimeDate > todayDate
          ? today.startOf('day')
          : currentTime.startOf('day');
      const _tomorrow =
        currentTimeDate > todayDate
          ? today.add(1, 'day').endOf('day')
          : currentTime.add(1, 'day').endOf('day');
      if (currentTime.isSame(_tomorrow, 'day')) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => (i < 4 ? null : i)).filter(
              (i) => i !== null
            ), // 禁用4点之后
        };
      }
    }
    return {};
  };

  const clockOutDisabledDate = (current) => {
    const clockInTime = formInstance.getFieldValue('clockIn');
    if (!clockInTime) {
      return clockInDisabledDate(current);
    }
    const clockInTimeDate = clockInTime.startOf('day').valueOf();
    const todayDate = today.startOf('day').valueOf();
    const currentDate = current.startOf('day').valueOf();
    if (clockInTimeDate > todayDate) {
      // 如果clockIn选择的明天，那么clock out打卡时间只能选择明天
      return currentDate !== clockInTimeDate;
    } else {
      const clockInHour = clockInTime.hour();
      const _tomorrow = clockInTime.add(1, 'day').endOf('day');
      const _today = clockInTime.startOf('day');
      if (clockInHour < 4) {
        return currentDate !== clockInTimeDate;
      }
      return current.isBefore(_today) || current.isAfter(_tomorrow);
    }
  };

  const clockOutDisabledTime = (currentTime) => {
    const clockInTime = formClockIn;
    const todayDate = today.startOf('day').valueOf();
    if (!clockInTime) {
      return clockInDisabledTime(currentTime);
    }
    const currentTimeDate = currentTime.startOf('day').valueOf();
    const clockInDate = clockInTime.startOf('day').valueOf();
    const clockInHour = clockInTime.hour();
    const beforeFourClock = Array.from({ length: 24 }, (_, i) =>
      i < 4 ? null : i
    ).filter((i) => i !== null); // 禁用四点及之后
    const afterFourClock = Array.from({ length: 4 }, (_, i) => i); // 禁用0点到4点
    if (currentTimeDate === clockInDate && clockInDate <= todayDate) {
      return {
        disabledHours: () =>
          clockInHour < 4 ? beforeFourClock : afterFourClock,
      };
    } else {
      return {
        disabledHours: () => beforeFourClock, // 禁用4点之后
      };
    }
  };

  const dateCellRender = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div
        className={[
          'ant-picker-cell-inner',
          current.isSame(today, 'day') ? 'ant-picker-cell-inner-today' : '',
        ].join(' ')}
      >
        {current.date()}
      </div>
    );
  };

  const clockInValidate = () => {
    formInstance.validateFields(['clockIn', 'clockOut']);
  };

  const clockOutValidate = () => {
    formInstance.validateFields(['clockIn', 'clockOut']);
  };


  const addBreak = () => {
    const newData = {
      breakId: null,
      breakStartTime: null,
      breakEndTime: null,
    };
    setBreaksList([...breaksList, newData]);
  };

  const removeTimeSheetBreak = (index) => {
    const timeSheetBreaks = timeSheetBreaksList.filter((_, i) => i !== index);
    setTimeSheetBreaksList(timeSheetBreaks);
  };

  const removeBreak = (index) => {
    const newBreaks = breaksList.filter((_, i) => i !== index);
    setBreaksList(newBreaks);
    formInstance.setFieldsValue({ breakTimeSheetList: newBreaks });
  };

  const getBreakAllList = async () => {
    const roleId = isEdit ? props.timeSheetInfo?.roleId : formInstance.getFieldValue('roleId');
    const res = await ApiGetBreakList({ roleId, archived: 0 });
    if (res?.code === 0) {
      setBreakSelectOption(res.data || []);
    }
  }

  const handleChangeBreaks = (type: 'breakId' | 'breakTime' | 'oldBreakTime', index: number, value: any) => {
    if (type === 'breakId') {
      setBreaksList((prev) => {
        const newBreaks = [...prev];
        newBreaks[index] = {
          ...newBreaks[index],
          ...value,
        };
        return newBreaks;
      });
    } else if (type === 'breakTime') {
      setBreaksList((prev) => {
        const newBreaks = [...prev];
        newBreaks[index] = {
          ...newBreaks[index],
          breakStartTime: value[0],
          breakEndTime: value[1],
        };
        return newBreaks;
      });
    }  else if (type === 'oldBreakTime') {
      setTimeSheetBreaksList((prev) => {
        const newBreaks = [...prev];
        newBreaks[index] = {
          ...newBreaks[index],
          breakStartTime: value[0],
          breakEndTime: value[1],
        };
        return newBreaks;
      });
    }
    console.log('handleChangeBreaks==>', type, index, value);
  }

  return (
    <div className="add-time-sheet">
      <Modal
        open={props.openModal}
        title={i18n?.t(
          isEdit ? 'sales_categories_edit_x' : 'sales_categories_add_x',
          { msg: i18n?.t('time_sheet') }
        )}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        className="add-time-sheet-modal"
        footer={[
          isEdit && (
            <Button key="delete" danger onClick={handleDelete} style={{ float: 'left' }}>
              {i18n?.t('frontOfHouse_pc_delete')}
            </Button>
          ),
          <Button key="cancel" onClick={handleCancel}>
            {i18n.t('cancel')}
          </Button>,
          <Button key="confirm" type="primary" onClick={handleOk}>
            {i18n.t('confirm')}
          </Button>,
        ]}
      >
        <Form
          {...layout}
          form={formInstance}
          validateTrigger="onSubmit"
          layout="vertical"
        >
          <Form.Item
            label={
              <div>
                <span>{i18n?.t('employee')}</span>
                <span style={{ color: '#ff4d4f' }}> * </span>
              </div>
            }
            name="employee"
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      i18n.t('please_select_x', { msg: i18n?.t('employee') })
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              disabled={isEdit}
              className="employee-select"
              options={employeeAllList}
              allowClear
              placeholder={i18n.t('pc_employee_report_all_employee')}
              onChange={handleEmployeeChange}
              onBlur={() => {
                formInstance.validateFields(['employee']);
              }}
            />
          </Form.Item>

          {isEdit ? (
            <Form.Item
              label={
                <div>
                  <span>{i18n?.t('dashboard_report_employee_timesheet')}</span>
                  <span style={{ color: '#ff4d4f' }}> * </span>
                </div>
              } >
              <Input
                value={props?.timeSheetInfo?.roleName}
                disabled={isEdit}
              ></Input>
            </Form.Item>
          ) : (
            <Form.Item
              label={
                <div>
                  <span>{i18n?.t('dashboard_report_employee_timesheet')}</span>
                  <span style={{ color: '#ff4d4f' }}> * </span>
                </div>
              }
              name="roleId"
              rules={[
                () => ({
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(
                        i18n.t('please_select_x', {
                          msg: i18n?.t('dashboard_report_employee_timesheet'),
                        })
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                disabled={isEdit}
                className="employee-select"
                options={roleList}
                allowClear
                placeholder={i18n.t('dashboard_report_employee_timesheet')}
                onBlur={() => {
                  formInstance.validateFields(['roleId']);
                  getBreakAllList()
                  formInstance.setFieldsValue({ breakTimeSheetList: [] });
                  setBreaksList([])
                }}
              />
            </Form.Item>
          )}

          {/* clock in */}
          <Form.Item
            label={
              <div>
                <span>{i18n?.t('timesheets_pc_clock_in')}</span>
                <span style={{ color: '#ff4d4f' }}> * </span>
              </div>
            }
            name="clockIn"
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      i18n.t('please_select_x', {
                        msg: i18n?.t('timesheets_pc_clock_in'),
                      })
                    );
                  }
                  const clockInTimeStamp = value.valueOf() || 0;
                  const clockOut = formInstance.getFieldValue('clockOut');
                  const clockOutTimeStamp = clockOut?.valueOf();
                  const duration = Math.abs(
                    clockOutTimeStamp - clockInTimeStamp
                  );
                  const hours24InMilliseconds = 24 * 60 * 60 * 1000;
                  if (clockInTimeStamp > clockOutTimeStamp) {
                    return Promise.reject(
                      i18n?.t('employee_time_sheets_clock_in_time')
                    );
                  }
                  if (duration > hours24InMilliseconds && !!clockOutTimeStamp) {
                    return Promise.reject(
                      i18n?.t('time_sheet_cross_business_day')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {today && (
              <DatePicker
                showTime={{ format: 'hh:mm' }}
                inputReadOnly
                format="MM/DD/YYYY hh:mm A"
                disabledDate={clockInDisabledDate}
                disabledTime={clockInDisabledTime}
                onBlur={clockInValidate}
                placeholder={i18n?.t('employee_time_sheets_start_time')}
                cellRender={dateCellRender}
                popupClassName="time-sheet-date-picker-popup"
                showNow={false}
                onChange={(value) => {
                  if (value) {
                    // 将秒设置为 00
                    const adjustedValue = value.second(0);
                    console.log(value, adjustedValue)
                    setFormClockIn(adjustedValue);
                  } else {
                    setFormClockIn(null);
                  }
                }}
              />
            )}
          </Form.Item>

          {/* clock out */}
          <Form.Item
            label={
              <div>
                <span>{i18n?.t('timesheets_pc_clock_out')}</span>
                <span style={{ color: '#ff4d4f' }}> * </span>
              </div>
            }
            name="clockOut"
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      i18n.t('please_select_x', {
                        msg: i18n?.t('timesheets_pc_clock_out'),
                      })
                    );
                  }
                  const clockOutTimeStamp = value.valueOf();
                  const clockIn = formInstance.getFieldValue('clockIn');
                  const clockInTimeStamp = clockIn?.valueOf() || 0;
                  const duration = Math.abs(
                    clockOutTimeStamp - clockInTimeStamp
                  );
                  const hours24InMilliseconds = 24 * 60 * 60 * 1000;
                  if (clockOutTimeStamp < clockInTimeStamp) {
                    return Promise.reject(
                      i18n?.t('employee_time_sheets_clock_out_time')
                    );
                  }
                  if (duration > hours24InMilliseconds && !!clockInTimeStamp) {
                    return Promise.reject(
                      i18n?.t('time_sheet_cross_business_day')
                    );
                  }
                  const clockInValue = clockIn?.startOf('day')?.valueOf();
                  const clockOutValue = value.startOf('day').valueOf();
                  const clockInHour = clockIn?.hour();
                  const clockOutHour = value.hour();
                  const todayValue = today.startOf('day').valueOf();
                  if (
                    clockInValue <= todayValue &&
                    clockInHour < 4 &&
                    clockOutHour >= 4
                  ) {
                    // 如果clockIn是今天及之前的时间，并且clockIn四点前，clockOut四点后则判断为跨营业日
                    return Promise.reject(
                      i18n?.t('time_sheet_cross_business_day')
                    );
                  }
                  if (clockOutValue > clockInValue && clockInHour < 4) {
                    // 如果clockOut大于clockIn，并且clockIn四点前，则clockOut也只能选四点前的时间，不允许跨天
                    return Promise.reject(
                      i18n?.t('time_sheet_cross_business_day')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {today && (
              <DatePicker
                showTime={{ format: 'hh:mm' }}
                inputReadOnly
                format="MM/DD/YYYY hh:mm A"
                disabledDate={clockOutDisabledDate}
                disabledTime={clockOutDisabledTime}
                onBlur={clockOutValidate}
                placeholder={i18n?.t('employee_time_sheets_end_time')}
                cellRender={dateCellRender}
                popupClassName="time-sheet-date-picker-popup"
                showNow={false}
                onChange={(value) => {
                  if (value) {
                    // 将秒设置为 00
                    const adjustedValue = value.second(0);
                    console.log(value, adjustedValue)
                    setFormClockOut(adjustedValue);
                  } else {
                    setFormClockOut(null);
                  }
                }}
              />
            )}
          </Form.Item>

          {/* break */}
          <Form.Item
            label={
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>{i18n?.t('dashboard_roles_edit_roles_break')}</span>
                <Button
                  type="link"
                  onClick={addBreak}
                >
                  + {i18n?.t('dashboard_roles_edit_roles_add_break')}
                </Button>
              </div>
            }
            name="breakId"
          >
            <div>
              {timeSheetBreaksList?.map((itemBreak, index) => (
                <div
                  key={index}
                  className='breaksListBox'
                >
                  <Tooltip title={
                    `${itemBreak.name} (${typeObj[itemBreak.type]})`}>
                    <Input
                      value={`${itemBreak.name} (${typeObj[itemBreak.type]})`}
                      style={{ width: 200, marginRight: 20 }}
                      disabled
                    />
                  </Tooltip>
                  <DatePicker.RangePicker
                    style={{ width: 400 }}
                    use12Hours
                    inputReadOnly
                    allowClear={ false }
                    showTime={{ format: 'hh:mm' }}
                    format="MM/DD/YYYY hh:mm A"
                    placeholder={['Start time', 'End time']}
                    onChange={ (value, timeString) => {handleChangeBreaks('oldBreakTime', index, timeString) } }
                    value={
                      [itemBreak.breakStartTime && dayjs(itemBreak.breakStartTime, 'MM/DD/YYYY hh:mm A'),
                        itemBreak.breakEndTime && dayjs(itemBreak.breakEndTime, 'MM/DD/YYYY hh:mm A')]
                    }
                  />
                  <Button
                    style={{ width: 30 }}
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => removeTimeSheetBreak(index)}
                  />
                </div>
              ))}
              {breaksList?.map((itemBreak, index) => (
                <div
                  key={index}
                  className='breaksListBox'
                >
                  <Form.Item
                    name={['breakTimeSheetList', index, 'breakId']}
                    rules={[
                      {
                        required: true,
                        message: i18n?.t('please_select'),
                      },
                    ]}
                  >
                    <Select
                      placeholder={i18n?.t('dashboard_roles_edit_roles_select_a_break')}
                      style={{ width: 200, marginRight: 20 }}
                      value={itemBreak.breakId}
                      onChange={(value) => {
                        const selectedOption = breakSelectOption.find(item => item.breakId === value);
                        handleChangeBreaks('breakId', index, selectedOption);
                      }}
                      optionLabelProp="label"
                    >
                      {
                        breakSelectOption.map(item => (
                          <Option
                            key={item.breakId}
                            value={item.breakId}
                            label={
                              `${item.name}(${typeObj[item.type]})`}
                          >
                            <Tooltip title={`${item.name}(${typeObj[item.type]})`}>
                              {item.name}({typeObj[item.type]})
                            </Tooltip>
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name={['breakTimeSheetList', index, 'breakTime']}
                    rules={[
                      {
                        required: true,
                        message: i18n?.t('please_select'),
                      },
                    ]}
                  >
                    <DatePicker.RangePicker
                      style={{ width: 400 }}
                      use12Hours
                      inputReadOnly
                      allowClear={ false }
                      showTime={{ format: 'hh:mm' }}
                      format="MM/DD/YYYY hh:mm A"
                      placeholder={['Start time', 'End time']}
                      onChange={ (value, timeString) => {handleChangeBreaks('breakTime', index, timeString) } }
                      value={
                        [itemBreak.breakStartTime && dayjs(itemBreak.breakStartTime, 'MM/DD/YYYY hh:mm A'),
                          itemBreak.breakEndTime && dayjs(itemBreak.breakEndTime, 'MM/DD/YYYY hh:mm A')]
                      }
                    />
                  </Form.Item>
                  <Button
                    style={{ width: 30 }}
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => removeBreak(index)}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default AddTimeSheet;
