import React, { useEffect, useState } from 'react'

import { Button, DatePicker, Flex, Form, Select, Space, Table, message } from 'antd'
import { globalState } from '@/stores'
import { SyncOutlined } from '@ant-design/icons'
import classNames from 'classnames';
import { ApiQueryEmployeeList, queryCheckOutReport } from '@/request/api';
import { getToday, formatCurrency } from '@/utils'
import dayjs from 'dayjs';
import './index.scss';
const EmployeeCheckoutReport = () => {
  const { i18n } = globalState
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)
  const [employeeAllList, setEmployeeAllList] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const columns = [
    {
      title: i18n.t('name') || 'Name',
      dataIndex: 'name',
      with: 220,
    },
    {
      title: i18n.t('dashboard_guest_count') || 'GuestCount',
      dataIndex: 'guestCount',
      width: 150,
    },
    {
      title: i18n.t('dashboard_net_sales') || 'NetSales',
      dataIndex: 'netSales',
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
      width: 150,
    },
    {
      title: i18n.t('dashboard_guest_avg') || 'GuestAvg',
      dataIndex: 'guestAvg',
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
      width: 150,
    },
    {
      title: i18n.t('dashboard_cash_sales') || 'CashSales',
      dataIndex: 'cashSales',
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
      width: 150,
    },
    {
      title: i18n.t('dashboard_card_tips') || 'CardTipAndGratuity',
      dataIndex: 'cardTipAndGratuity',
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
      width: 180,
    },
    {
      title: i18n.t('dashboard_total_tips') || 'TotalTip',
      dataIndex: 'totalTip',
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
      width: 150,
    },
    {
      title: i18n.t('dashboard_cash_due') || 'CashDue',
      dataIndex: 'cashDue',
      render: (val) => !!val ? formatCurrency(val) : 'N/A',
      width: 150,
    }
  ]
  const fetchEmployeeList = async () => {
    const _params = { needPage: false };
    const resp = await ApiQueryEmployeeList(_params);
    const { list = [] } = resp?.data;
    setEmployeeAllList(
      (list || []).map((x) => ({
        label: x.employee.fullName,
        value: x.employee.employeeId,
      }))
    );
  };
  const disabledDate = (current: dayjs.Dayjs) => {
    const today = dayjs().endOf('day');
    const sevenDaysAgo = dayjs().subtract(6, 'day').startOf('day');
    return current.isAfter(today) || current.isBefore(sevenDaysAgo);
  };
  const formInitialValues = async () => {
    const _date = await getToday()
    form.setFieldValue('date', _date)
    return {
      date: _date,
      employeeIdList: null
    }
  }
  const handleFormValuesChange = async (val) => {
    console.log(form.getFieldsValue())
    fetchTableData()
  }
  const fetchTableData = async () => {
    setLoading(true)
    try {
      const { date, employeeIdList } = form.getFieldsValue()
      const _params = {
        startTime: dayjs(date).format('YYYY-MM-DD') + ' 00:00:00',
        endTime: dayjs(date).format('YYYY-MM-DD') + ' 23:59:59',
        employeeIdList: employeeIdList && employeeIdList.length > 0 ? employeeIdList : null
      }
      const resp = await queryCheckOutReport(_params)
      const data = resp?.data.checkOutDTOList?.concat(resp?.data.checkOutDTOTotal) || []
      setDataSource(data)
    } catch (e) {
      message.error(e?.message)
    }
    // setDataSource(data.checkOutDTOList.concat(data.checkOutDTOTotal) || [])
    setLoading(false)
  }
  const init = async () => {
    await formInitialValues()
    fetchEmployeeList()
    fetchTableData();
  };
  useEffect(() => {
    // const formData = formInitialValues()
    // console.log(formData)
    // form.setFieldsValue(formData)
    init()
  }, [])
  return <div className='employee-checkout-report'>
    <Flex className="filter-wrap" justify='space-between'>
      <div className="left-wrap">
        <Form
          form={form}
          layout="inline"
          onValuesChange={handleFormValuesChange}
          initialValues={formInitialValues}
        >
          <Form.Item name={'date'}>
            <DatePicker
              disabledDate={disabledDate}
              inputReadOnly
              allowClear={false}
              format={'MM/DD/YYYY'} />
          </Form.Item>
          <Form.Item name={'employeeIdList'}>
            <Select
              options={employeeAllList}
              showSearch
              mode="multiple"
              optionFilterProp="label"
              style={{ width: 250 }}
              allowClear
              maxTagCount={1}
              placeholder={i18n.t('pc_employee_report_all_employee')}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="right-wrap">
        <Button type="link" onClick={fetchTableData}>
          <SyncOutlined />
        </Button>
      </div>
    </Flex>
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      scroll={{ x: 'max-content' }}
      pagination={false}
      rowClassName={ (_, index) => classNames('row', { 'sticky-row': index === dataSource.length - 1 }) }
    />
  </div>
}

export default EmployeeCheckoutReport