import userInfo from '@/stores/userInfo';
import { toJS } from 'mobx';
interface IPermission {
  id: number;
  code: string;
}

// 模拟权限数据
const permissionList: IPermission[] = [
  { id: 1528, code: 'ONLINE_PICK_UP' },
  { id: 1529, code: 'ONLINE_DOORDASH' },
  { id: 1530, code: 'EDIT_TIME_SHEETS' },
];

/**
 * 检查用户是否拥有指定的权限
 * @param permissionCode 权限code码
 * @returns 布尔值，表示用户是否拥有该权限
 */
export function checkPermission (permissionCode: string): boolean {
  // 查找权限code对应的权限对象
  const permission = permissionList.find((p) => p.code === permissionCode);
  // 如果找不到对应的权限，返回false
  if (!permission) {
    return false;
  }

  return toJS(userInfo.getPermissionIds).includes(permission.id);
}

// 批量检查多个权限
export function checkPermissions (permissionCodes: string[]): boolean {
  return permissionCodes.every((code) => checkPermission(code));
}

// 获取用户所有权限
export function getUserPermissions (): string[] {
  return permissionList
    .filter((permission) => toJS(userInfo.getPermissionIds).includes(permission.id))
    .map((permission) => permission.code);
}
